<template>
  <el-container>
    <el-main class="infinite-list" v-infinite-scroll="loadInfinite"
             style="overflow:auto; height:calc(100vh - 52px); padding-left: 0; padding-right: 0">
      <el-backtop target=".infinite-list"></el-backtop>
      <div class="container" style="padding-left: 5px; padding-right: 5px">
        <el-row type="flex" justify="center">
          <el-col :xs="24" :sm="22" :md="18" :lg="16" :xl="16">
            <!--内容列表-->
            <div class="grid-content bg-purple content-list">
              <el-card class="box-card" :body-style="{ padding: 0 }">
                <template v-for="(item, index) in commentListData">
                  <div :key="index" class="discuss-data-item">
                    <div class="profile" style="margin-bottom: 0">
                      <div class="profile-left" style="margin-right: 5px">
                        <el-avatar size="medium" :src="item.figureurl_qq_1"></el-avatar>
                      </div>
                      <div class="profile-middle">
                        <h4>{{ item.nickname ? item.nickname : '匿名用户' }}</h4>
                        <div class="profile-time">{{ item.create_time.substring(0, 10) }}</div>
                      </div>
                      <el-button slot="reference" type="text" style="margin-right: 10px"
                                 @click="openDiscussDetail(item.blog_id)">Blog:{{ item.blog_id }}
                      </el-button>
                      <el-popconfirm
                          v-if="$store.state.userinfo.isAdmin"
                          title="确定删除吗？"
                          @confirm="delComment(item.id, index)"
                      >
                        <el-button slot="reference" type="text">删除</el-button>
                      </el-popconfirm>
                    </div>
                    <div style="font-size: 15px">
                      <div v-html="replaceSrc(item.content)"></div>
                    </div>
                  </div>
                  <el-divider :key="index"></el-divider>
                </template>
              </el-card>
            </div>
          </el-col>
        </el-row>
      </div>
    </el-main>
  </el-container>
</template>

<script>
import axios from 'axios'

function replaceSrc(txt) {
  // var reg =
  //   /(((https?:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/gi;
  let reg =
      /(?:(?:https?|ftp|file):\/\/|www\.|ftp\.)(?:\([-A-Za-z0-9+&@#/%=~_|$?!:,.]*\)|[-A-Za-z0-9+&@#/%=~_|$?!:,.])*(?:\([-A-Za-z0-9+&@#/%=~_|$?!:,.]*\)|[A-Za-z0-9+&@#/%=~_|$])/gi;
  return txt.replace(reg, function (item) {
    return "<a href='" + item + "' target='_blank'>" + item + "</a>";
  })
}

export default {
  name: "Comment",
  data() {
    return {
      infiniteCount: 1,
      commentListData: [],
      replaceSrc,
    }
  },
  methods: {
    loadInfinite() {
      this.getAllCommentList()
    },
    getAllCommentList() {
      axios({
        method: 'get',
        url: '/api/blogs/get_all_comment_list',
        params: {
          infiniteCount: this.infiniteCount,
        }
      }).then(r => {
        if (r.data.code === 0) {
          this.infiniteCount += 1
          this.commentListData = this.commentListData.concat(r.data.list)
        }
      })
    },
    delComment(itemId, index) {
      axios({
        method: 'post',
        url: '/api/blogs/del_comment',
        data: {
          obj_id: itemId,
          blog_id: this.$route.params.blogId,
        }
      }).then(r => {
        if (r.data.code === 0) {
          this.commentListData.splice(index, 1)
          this.$message({
            message: '删除成功',
            type: 'success'
          });
        }
      })
    },
    openDiscussDetail(blogId) {
      window.open('/discuss/' + blogId)
    },
  }
}
</script>

<style scoped>
.profile {
  display: flex;
  align-items: center;
  justify-content: inherit;
  margin-bottom: 10px;
}

.profile-middle {
  flex: 1;
}

.profile-time {
  font-size: 12px;
  color: #8e969c;
}


.biliVideo iframe {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}


.posting-bottom-bar .left {
  display: flex;
  flex-grow: 1;
}

.posting-bottom-bar .left .left-item {
  display: flex;
  align-items: center;
  font-size: 15px;
  margin-right: 15px;
}

.posting-bottom-bar .left .left-item:hover {
  color: #409eff;
  cursor: pointer;
}

.posting-bottom-bar .right {
  display: flex;
  align-items: center;
}

.discuss-data-item {
  padding: 20px;
}


.bg-purple {
  /*background: #d3dce6;*/
}


.grid-content {
  border-radius: 4px;
  min-height: 36px;
}


.textarea-wrapper input {
  outline: none;
  resize: none;
  width: 100%;
  background: transparent;
  border: none;
  font-weight: 600;
  font-size: 20px;
}

.textarea-wrapper textarea {
  outline: none;
  resize: none;
  width: 100%;
  background: transparent;
  border: none;
  font-size: 18px;
  min-height: 54px;
}


.posting-bottom-bar .left {
  display: flex;
  flex-grow: 1;
}

.posting-bottom-bar .left .left-item {
  display: flex;
  align-items: center;
  font-size: 15px;
  margin-right: 15px;
}

.posting-bottom-bar .left .left-item:hover {
  color: #409eff;
  cursor: pointer;
}

.posting-bottom-bar .right {
  display: flex;
  align-items: center;
}


.content-list {
  margin-top: 20px;
}

.profile {
  display: flex;
  align-items: center;
  justify-content: inherit;
}


.profile-time {
  font-size: 12px;
  color: #8e969c;
}

.discuss-data-item {
  padding: 20px;
}


.biliVideo iframe {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

.el-divider--horizontal {
  margin: 0;
}

.profile-middle {
  flex: 1;
}
</style>